<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="col-auto text-center">
        <div>{{ $t('course.module_wizard.first_step_title') }}</div>
        <div>{{ $t('course.module_wizard.first_step_subtitle') }}</div>
      </div>
    </div>
    <course-module-form :course-module="courseModule" ref="courseModuleForm"/>
  </div>
</template>

<script>
import CourseModuleForm from "../forms/CourseModuleForm";
import CourseModule from "../../../models/courseModule";

export default {
  name: "CourseModuleWizardFirstStep",
  components: {CourseModuleForm},
  data() {
    return {
      courseModule: this.$_.cloneDeep(CourseModule)
    }
  },
  methods: {
    validate() {
      return this.$refs.courseModuleForm.validate();
    }
  }
}
</script>

<style scoped>

</style>
