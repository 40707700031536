var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"courseModuleForm"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('ul',{staticClass:"list-group list-group-flush"},[_c('list-group-item-component',{attrs:{"label":_vm.$t('fields.code'),"label-width":_vm.labelWidth}},[_c('template',{slot:"value"},[_c('ValidationProvider',{staticClass:"flex-grow-1",attrs:{"name":"code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{staticClass:"mb-0",class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","type":"text","error":errors[0]},model:{value:(_vm.courseModule.code),callback:function ($$v) {_vm.$set(_vm.courseModule, "code", $$v)},expression:"courseModule.code"}})]}}])})],1)],2),_c('list-group-item-component',{attrs:{"label":_vm.$t('fields.title'),"label-width":_vm.labelWidth}},[_c('template',{slot:"value"},[_c('ValidationProvider',{staticClass:"flex-grow-1",attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{staticClass:"mb-0",class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","type":"text","error":errors[0]},model:{value:(_vm.courseModule.title),callback:function ($$v) {_vm.$set(_vm.courseModule, "title", $$v)},expression:"courseModule.title"}})]}}])})],1)],2),_c('list-group-item-component',{attrs:{"label":_vm.$t('fields.lesson_number'),"label-width":_vm.labelWidth}},[_c('template',{slot:"value"},[_c('base-input',{staticClass:"flex-grow-1 mb-0",attrs:{"type":"number"},model:{value:(_vm.courseModule.lesson_number),callback:function ($$v) {_vm.$set(_vm.courseModule, "lesson_number", $$v)},expression:"courseModule.lesson_number"}})],1)],2)],1)]),_c('div',{staticClass:"col-12 col-md-6"},[_c('ul',{staticClass:"list-group list-group-flush"},[_c('list-group-item-component',{attrs:{"label":_vm.$t('fields.description'),"label-width":_vm.labelWidth}},[_c('template',{slot:"value"},[_c('base-text-area',{staticClass:"flex-grow-1",attrs:{"rows":4},model:{value:(_vm.courseModule.description),callback:function ($$v) {_vm.$set(_vm.courseModule, "description", $$v)},expression:"courseModule.description"}})],1)],2)],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }