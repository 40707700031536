<template>
  <simple-wizard
    @tab-change="tabChange"
    @onCompleted="completeWizard"
    :finishButtonText="$t('common.finish')"
    :nextButtonText="$t('common.next')"
    :prevButtonText="$t('common.prev')"
  >
    <template slot="header">
      <h4 class="description">{{ $t('course.module_wizard.subtitle') }}</h4>
    </template>

    <wizard-tab :before-change="() => validateStep(1)">
      <template slot="label">
        <octo-icon icon="list"></octo-icon>
        <p>{{ $t('course.module_wizard.form') }}</p>
      </template>
      <course-module-wizard-first-step ref="courseModuleWizardFirstStep"/>
    </wizard-tab>

    <wizard-tab :before-change="() => validateStep(2)">
      <template slot="label">
        <octo-icon icon="filter"></octo-icon>
        <p>{{ $t('course.module_wizard.subjects') }}</p>
      </template>
      <course-module-wizard-second-step ref="courseModuleWizardSecondStep"/>
    </wizard-tab>

    <wizard-tab :before-change="() => validateStep(2)">
      <template slot="label">
        <octo-icon icon="checked"></octo-icon>
        <p>{{ $t('course.module_wizard.report') }}</p>
      </template>
      <course-module-wizard-third-step ref="courseModuleWizardThirdStep" :course-module="courseModule"/>
    </wizard-tab>
  </simple-wizard>
</template>

<script>

import {endpoints} from "@/routes/endpoints";
import SimpleWizard from "@/components/Wizard/Wizard";
import WizardTab from "@/components/Wizard/WizardTab";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import CourseModule from "@/models/courseModule";
import CourseModuleWizardFirstStep from "@/pages/Courses/course-module-wizard/CourseModuleWizardFirstStep";
import CourseModuleWizardSecondStep from "@/pages/Courses/course-module-wizard/CourseModuleWizardSecondStep";
import CourseModuleWizardThirdStep from "@/pages/Courses/course-module-wizard/CourseModuleWizardThirdStep";
import {mapActions} from "vuex";

export default {
  name: "CourseModuleWizard",
  components: {
    OctoIcon,
    WizardTab,
    SimpleWizard,
    CourseModuleWizardFirstStep,
    CourseModuleWizardSecondStep,
    CourseModuleWizardThirdStep,
  },
  data() {
    return {
      courseModule: this.$_.cloneDeep(CourseModule),
    }
  },
  methods: {
    ...mapActions({
      getCourseModules: 'common/getCourseModules'
    }),

    async validateStep(step) {
      let check = false;
      switch (step) {
        case 1:
          const data = await this.$refs.courseModuleWizardFirstStep.validate();
          if (data) {
            this.courseModule = data;
            check = true;
          }
          break;
        case 3:
        case 2:
          check = true;
          break;
        default:
          check = false;
          break;
      }

      if (!check) {
        this.$notify({type: 'danger', message: this.$t('notifications.wizard_step_error')});
      }

      return check;
    },
    tabChange(oldTab, newTab) {
      switch (newTab.tabId) {
        case '0':
        case '1':
          break;
        case '2':
          this.$set(this.courseModule, 'subjects', this.$refs.courseModuleWizardSecondStep.getSubjects());
          break;
        default:
          break;
      }
    },
    completeWizard() {
      this.$fullLoading.show();
      this.$api.post(endpoints.COURSE_MODULE_STORE, this.courseModule)
        .then(() => {
          this.getCourseModules();
          this.$emit('onCloseWizardModal');
          this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        })
      .finally(() => {
        this.$fullLoading.hide();
      })
    }
  }
}
</script>

<style scoped>

</style>
