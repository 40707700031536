<template>
  <div class="container-fluid">
    <div class="row align-items-end" v-if="!readOnly">
      <div class="col flex-grow-1">
        <base-input :label="$t('common.subjects')">
          <el-select
            class="select-default text-uppercase"
            value-key="code"
            :placeholder="$t('common.type_to_search')"
            v-model="selectedSubject"
          >
            <el-option
              v-for="option in subjectOptions"
              class="select-default text-uppercase"
              :value="option"
              :label="option.title"
              :key="option.code"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-auto form-group">
        <base-button @click="addSubject" icon link class="ml-auto text-capitalize mb-0">
          <octo-icon icon="add"/>
        </base-button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <ul class="list-group list-group-flush subject-list">
          <list-group-item-component v-for="(subject, index) in subjectsLocal" v-bind:key="`subject-${index}`">
            <template slot="label">
              <div class="standard-label text-truncate">
                <label-theme-component>{{ subject.code + ': ' }}</label-theme-component>
                {{ subject.title }}
              </div>
            </template>
            <template slot="value">
              <base-button class="ml-2 my-0" type="danger" size="sm" icon link @click="deleteSubject(index)" v-if="!readOnly">
                <octo-icon icon="wrong"/>
              </base-button>
            </template>
          </list-group-item-component>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

import OctoIcon from "@/components/octo-icon/OctoIcon";
import BaseAlert from "@/components/BaseAlert";
import {Select, Option} from "element-ui";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import {mapGetters} from "vuex";

export default {
  name: "SubjectPicker",
  components: {
    LabelThemeComponent,
    ListGroupItemComponent,
    BaseAlert,
    OctoIcon,
    [Select.name]: Select,
    [Option.name]: Option
  },
  props: {
    selectedSubjects: {
      type: Array,
      default: () => []
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedSubject: null,
      subjectsLocal: [],
    }
  },
  mounted() {
    this.subjectsLocal = this.$_.cloneDeep(this.selectedSubjects);
  },
  computed: {
    ...mapGetters({
      subjects: 'common/subjects'
    }),

    subjectOptions() {
      const subjectIds = this.$_.map(this.subjectsLocal, subject => {
        return subject?.id;
      });

      return this.$_.filter(this.subjects, subject => {
        return !this.$_.find(subjectIds, id => {
          return subject.id === id;
        });
      });
    }
  },
  methods: {
    getSubjects() {
      this.$_.each(this.subjectsLocal, (subject, index) => {
        this.$set(subject, 'order', index);
      });

      return this.subjectsLocal;
    },

    deleteSubject: function (index) {
      this.subjectsLocal.splice(index, 1);
    },

    addSubject() {
      this.subjectsLocal.push(this.selectedSubject);
      this.selectedSubject = null;
    }
  }
}
</script>

<style scoped>
.subject-list {
  max-height: 250px;
  overflow: auto;
}
</style>
