var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"subjectForm"},[_c('ul',{staticClass:"list-group list-group-flush"},[_c('list-group-item-component',{attrs:{"label":_vm.$t('fields.code'),"label-width":_vm.labelWidth}},[_c('template',{slot:"value"},[_c('ValidationProvider',{staticClass:"flex-grow-1",attrs:{"name":"code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{staticClass:"mb-0",class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","type":"text","error":errors[0]},model:{value:(_vm.subject.code),callback:function ($$v) {_vm.$set(_vm.subject, "code", $$v)},expression:"subject.code"}})]}}])})],1)],2),_c('list-group-item-component',{attrs:{"label":_vm.$t('fields.title'),"label-width":_vm.labelWidth}},[_c('template',{slot:"value"},[_c('ValidationProvider',{staticClass:"flex-grow-1",attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{staticClass:"mb-0",class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","type":"text","error":errors[0]},model:{value:(_vm.subject.title),callback:function ($$v) {_vm.$set(_vm.subject, "title", $$v)},expression:"subject.title"}})]}}])})],1)],2),_c('list-group-item-component',{attrs:{"label":_vm.$t('fields.description'),"label-width":_vm.labelWidth}},[_c('template',{slot:"value"},[_c('base-text-area',{staticClass:"flex-grow-1",model:{value:(_vm.subject.description),callback:function ($$v) {_vm.$set(_vm.subject, "description", $$v)},expression:"subject.description"}})],1)],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }