<template>
  <octo-table
    enable-add
    show-filters
    :show-filters-collapse="false"
    :action="endpoints.DATATABLES.locations"
    :fields="fields"
    :filters="filters"
    :name="name"
    @onResetFilters="filters = {}"
    @onAdd="$emit('onAddLocation')"
  >

    <template v-slot:alias="data">
      <div class="text-truncate">{{ data.row.alias }}</div>
    </template>

    <template v-slot:country="data">
      <div class="text-truncate">{{ data.row.country }}</div>
    </template>

    <template v-slot:region="data">
      <div class="text-truncate">{{ data.row.region }}</div>
    </template>

    <template v-slot:province="data">
      <div class="text-truncate">{{ data.row.province }}</div>
    </template>

    <template v-slot:locality="data">
      <div class="text-truncate">{{ data.row.locality }}</div>
    </template>

    <template v-slot:street_address="data">
      <div class="text-truncate">{{ data.row.street_address }}</div>
    </template>

    <template v-slot:postal_code="data">
      <div class="text-truncate">{{ data.row.postal_code }}</div>
    </template>

    <template v-slot:actions="data">
      <div class="d-flex justify-content-end">
        <el-tooltip :content="$t('common.edit')" :open-delay="300" placement="top">
          <base-button class="mx-1" size="sm" link icon @click="$emit('onOpenLocation', data.row)">
            <octo-icon icon="edit"/>
          </base-button>
        </el-tooltip>
      </div>
    </template>
  </octo-table>
</template>

<script>
import OctoTable from "@/components/octo-table/OctoTable";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {endpoints} from "@/routes/endpoints";
import {mapGetters} from "vuex";

export default {
  name: "LocationDatatable",
  components: {OctoIcon, OctoTable},
  data() {
    return {
      name: 'LocationDatatable',
      endpoints: endpoints,
      filters: {},
      fields: [
        {prop: 'alias', label: 'alias', width: 150, align: 'left'},
        {prop: 'country', label: 'country', slot: true, align: 'center'},
        {prop: 'region', label: 'region', slot: true, align: 'left'},
        {prop: 'province', label: 'province', slot: true, align: 'center'},
        {prop: 'locality', label: 'locality', slot: true, align: 'left'},
        {prop: 'street_address', label: 'street_address', slot: true, align: 'left'},
        {prop: 'postal_code', label: 'postal_code', slot: true, align: 'center'},
        {prop: 'actions', label: 'actions', width: 150, slot: true},
      ],
    }
  },
  beforeMount() {
    this.filters = this.getFilters(this.name);
  },
  computed: {
    ...mapGetters({
      getFilters: 'common/datatableFilters',
    }),
  }
}
</script>

<style scoped>

</style>
