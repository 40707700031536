<template>
  <div class="row">
    <div class="col-12">
      <octo-header-button :title="$t('common.course_modules')"/>
    </div>
    <div class="col-12">
      <course-module-datatable
        :key="`module-datatable-${datatableKey}`"
        @onAddCourseModule="openModalWizard"
        @onOpenCourseModule="openCourseModule"
      />
    </div>
    <modal centered bodyClasses="p-1" modalClasses="modal-xl" :show.sync="showModalWizard">
      <h5 slot="header" class="modal-title text-uppercase">
        {{ $t('course.module_wizard.title') }}
      </h5>
      <course-module-wizard
        ref="courseModuleWizard"
        :key="`wizard-${wizardKey}`"
        @onCloseWizardModal="showModalWizard = false; datatableKey++;"
      />
    </modal>

    <modal centered bodyClasses="p-1" modalClasses="modal-xl" :show.sync="showModalForm">
      <h5 slot="header" class="modal-title text-uppercase">
        {{ $t('course.edit_module') }}: {{ currentCourseModule.id | udid }}
      </h5>
      <course-module-form
        class="mb-4"
        ref="courseModuleForm"
        :course-module="currentCourseModule"
        :key="`form-${formKey}`"
      />
      <hr>
      <subject-picker
        ref="subjectPicker"
        :selected-subjects="currentCourseModule | optional('subjects')"
        :key="`subject-picker-${formKey}`"
      />
      <template slot="footer">
        <base-button link class="text-capitalize" @click="saveCourseModule">
          {{ $t('common.update') }}
        </base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import CourseModuleDatatable from "@/pages/Courses/datatables/CourseModuleDatatable";
import CourseModuleWizard from "@/pages/Courses/course-module-wizard/CourseModuleWizard";
import Modal from "@/components/Modal";
import CourseModuleForm from "@/pages/Courses/forms/CourseModuleForm";
import CourseModule from "@/models/courseModule";
import SubjectPicker from "@/pages/Didactics/components/SubjectPicker";
import {endpoints} from "@/routes/endpoints";
import {mapActions} from "vuex";

export default {
  name: "DidacticsCourseModules",
  components: {SubjectPicker, CourseModuleForm, CourseModuleWizard, Modal, CourseModuleDatatable, OctoHeaderButton},
  data() {
    return {
      formKey: 0,
      datatableKey: 0,
      wizardKey: 0,
      showModalWizard: false,
      showModalForm: false,
      currentCourseModule: _.cloneDeep(CourseModule),
    }
  },
  methods: {
    ...mapActions({
      getCourseModules: 'common/getCourseModules'
    }),

    openModalWizard() {
      this.wizardKey++;
      this.showModalWizard = true;
    },
    openCourseModule(courseModule) {
      this.currentCourseModule = this.$_.cloneDeep(courseModule);
      this.formKey++;
      this.showModalForm = true;
    },
    saveCourseModule: async function () {
      try {
        const courseModuleData = await this.$refs.courseModuleForm.validate();
        courseModuleData.subjects = await this.$refs.subjectPicker.getSubjects();
        this.$fullLoading.show();

        await this.$api.put(
          endpoints.COURSE_MODULE_UPDATE.replace('{id}', courseModuleData.id),
          courseModuleData
        );

        this.showModalForm = false;
        this.datatableKey++;
        await this.getCourseModules();
        this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
      } finally {
        this.$fullLoading.hide();
      }
    },
  }
}
</script>

<style scoped>

</style>
