import { render, staticRenderFns } from "./SubjectDatatable.vue?vue&type=template&id=04f2ec38&scoped=true&"
import script from "./SubjectDatatable.vue?vue&type=script&lang=js&"
export * from "./SubjectDatatable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04f2ec38",
  null
  
)

export default component.exports