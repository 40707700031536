<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <tabs centered square>
          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('common.course_modules') }}</div>
              </div>
            </template>
            <didactics-course-modules/>
          </tab-pane>
          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('common.subjects') }}</div>
              </div>
            </template>
            <didactics-subjects/>
          </tab-pane>
          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('common.locations') }}</div>
              </div>
            </template>
            <didactics-locations/>
          </tab-pane>
        </tabs>
      </div>
    </div>
  </div>
</template>

<script>
import DidacticsCourseModules from "./components/DidacticsCourseModules";
import DidacticsSubjects from "./components/DidacticsSubjects";
import {Tabs, TabPane} from '@/components';
import DidacticsLocations from "./components/DidacticsLocations";

export default {
  name: "DidacticsPage",
  components: {DidacticsLocations, Tabs, TabPane, DidacticsSubjects, DidacticsCourseModules}
}
</script>

<style scoped>

</style>
