<template>
  <octo-table
    enable-add
    show-filters
    :show-filters-collapse="false"
    :action="endpoints.DATATABLES.courseModules"
    :fields="fields"
    :filters="filters"
    :name="name"
    @onResetFilters="filters = {}"
    @onAdd="$emit('onAddCourseModule')"
  >
    <template v-slot:active="data">
      <octo-icon :icon="data.row.active ? 'checked' : 'wrong'"/>
    </template>

    <template v-slot:title="data">
      <div class="text-truncate">{{ data.row.title }}</div>
    </template>

    <template v-slot:description="data">
      <div class="text-truncate">{{ data.row.description }}</div>
    </template>

    <template v-slot:actions="data">
      <div class="d-flex justify-content-end">
        <base-button class="mx-1" size="sm" link icon @click="$emit('onOpenCourseModule', data.row)">
          <octo-icon icon="edit"/>
        </base-button>
      </div>
    </template>
  </octo-table>
</template>

<script>
import OctoTable from "@/components/octo-table/OctoTable";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {endpoints} from "@/routes/endpoints";
import {mapGetters} from "vuex";

export default {
  name: "CourseModuleDatatable",
  components: {OctoIcon, OctoTable},
  data() {
    return {
      name: 'CourseModuleDatatable',
      endpoints: endpoints,
      filters: {},
      fields: [
        {prop: 'code', label: 'code', width: 200, align: 'left', fixed:true},
        {prop: 'title', label: 'title', width: 250, slot: true, align: 'left'},
        {prop: 'lesson_number', label: 'lesson_number', width: 150, align: 'left'},
        {prop: 'description', label: 'description', minWidth: 100, slot: true, align: 'left'},
        {prop: 'actions', label: 'actions', width: 150, slot: true, fixed:'right'},
      ],
    }
  },
  beforeMount() {
    this.filters = this.getFilters(this.name);
  },
  computed: {
    ...mapGetters({
      getFilters: 'common/datatableFilters',
    }),
  }
}
</script>

<style scoped>

</style>
