import { render, staticRenderFns } from "./LocationDatatable.vue?vue&type=template&id=34e07827&scoped=true&"
import script from "./LocationDatatable.vue?vue&type=script&lang=js&"
export * from "./LocationDatatable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34e07827",
  null
  
)

export default component.exports