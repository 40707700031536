const Location = {
  id: null,
  alias: '',
  country: '',
  region: null,
  province: '',
  locality: '',
  street_address: '',
  postal_code: ''
};

export default Location
