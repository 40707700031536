<template>
  <div class="row">
    <div class="col-12">
      <octo-header-button :title="$t('common.locations')"/>
    </div>
    <div class="col-12">
      <location-datatable
        @onOpenLocation="openModal"
        @onAddLocation="openModal"
        :key="`datatable-${locationDatatableKey}`"
      />
    </div>
    <modal centered :show.sync="showModal">
      <h5 slot="header" class="modal-title text-uppercase">
        {{ $t('common.location') }}
      </h5>
      <location-form ref="locationForm" :location="currentLocation" :key="`form-${locationFormKey}`"/>
      <template slot="footer">
        <base-button link @click="saveCurrentLocation">
          {{ $t('common.save') }}
        </base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import LocationDatatable from "@/pages/Didactics/datatables/LocationDatatable";
import Modal from "@/components/Modal";
import LocationForm from "@/pages/Didactics/forms/LocationForm";
import Location from "@/models/location";
import {endpoints} from "@/routes/endpoints";
import {mapActions} from "vuex";

export default {
  name: "DidacticsLocations",
  components: {LocationForm, Modal, LocationDatatable, OctoHeaderButton},
  data() {
    return {
      endpoints: endpoints,
      currentLocation: Location,
      showModal: false,
      locationFormKey: 0,
      locationDatatableKey: 0,
    }
  },
  methods: {
    ...mapActions({
      getLocations: 'common/getLocations'
    }),

    openModal(location) {
      this.currentLocation = this.$_.cloneDeep(this.$_.isEmpty(location) ? Location : location);
      this.showModal = true;
      this.locationFormKey++;
    },

    saveCurrentLocation: async function () {
      try {
        let locationData = await this.$refs.locationForm.validate();

        this.$fullLoading.show();

        await this.$api({
          method: locationData.id ? 'put' : 'post',
          url: locationData.id
            ? endpoints.LOCATION_UPDATE.replace('{id}', locationData.id)
            : endpoints.LOCATION_STORE,
          data: locationData
        });

        this.locationDatatableKey++;
        this.showModal = false;
        this.getLocations();
        this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
      } finally {
        this.$fullLoading.hide();
      }
    },
  }
}
</script>

<style scoped>

</style>
