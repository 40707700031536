<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="col-auto text-center">
        <div>{{ $t('course.module_wizard.third_step_info') }}</div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 col-md-8">
        <card class="shadow" header-classes="pb-0 " body-classes="standard-card-body">
          <div slot="header">
            <h4 class="card-title text-capitalize">{{ $t('page.course_module') }}</h4>
          </div>
          <ul class="list-group list-group-flush">
            <list-group-item-component v-for="(key, index) in moduleKeysToShow"
                                       v-bind:key="`module-keys-${index}`"
                                       :label="$t(`fields.${key}`)"
                                       :value="courseModule[key]"
            />
          </ul>
        </card>
      </div>
      <div class="col-12 col-md-4">
        <card class="shadow" header-classes="pb-0 " body-classes="standard-card-body">
          <div slot="header">
            <h4 class="card-title text-capitalize">{{ $t('page.selected_subjects') }}</h4>
          </div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item text-center text-capitalize" v-show="courseModule.subjects.length === 0">
              {{ $t('common.empty_list') }}
            </li>
            <list-group-item-component v-for="(subject, index) in courseModule.subjects"
                                       v-bind:key="`selectedSubjects-${index}`"
                                       :label="subject.code"
                                       :value="subject.title"
            />
          </ul>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import CourseModule from "@/models/courseModule";
import BaseAlert from "@/components/BaseAlert";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import NoDataComponent from "../../../components/NoDataComponent";

export default {
  name: "CourseModuleWizardThirdStep",
  components: {NoDataComponent, ListGroupItemComponent, BaseAlert},
  data() {
    return {
      moduleKeysToShow: ['code', 'title', 'lesson_number', 'description']
    }
  },
  props: {
    courseModule: {
      type: Object,
      default: () => CourseModule
    }
  }
}
</script>

<style scoped>

</style>
