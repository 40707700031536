<template>
  <ValidationObserver ref="subjectForm">
    <ul class="list-group list-group-flush">
      <list-group-item-component :label="$t('fields.code')" :label-width="labelWidth">
        <template slot="value">
          <ValidationProvider
            class="flex-grow-1"
            name="code"
            rules="required"
            v-slot="{ passed, failed, errors }"
          >
            <base-input
              required
              v-model="subject.code"
              class="mb-0"
              type="text"
              :error="errors[0]"
              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
            </base-input>
          </ValidationProvider>
        </template>
      </list-group-item-component>
      <list-group-item-component :label="$t('fields.title')" :label-width="labelWidth">
        <template slot="value">
          <ValidationProvider
            class="flex-grow-1"
            name="title"
            rules="required"
            v-slot="{ passed, failed, errors }"
          >
            <base-input
              required
              v-model="subject.title"
              class="mb-0"
              type="text"
              :error="errors[0]"
              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
            </base-input>
          </ValidationProvider>
        </template>
      </list-group-item-component>
      <list-group-item-component :label="$t('fields.description')" :label-width="labelWidth">
        <template slot="value">
          <base-text-area v-model="subject.description" class="flex-grow-1"/>
        </template>
      </list-group-item-component>
    </ul>
  </ValidationObserver>
</template>

<script>
  import Subject from "@/models/subject";
  import ListGroupItemComponent from "@/components/ListGroupItemComponent";
  import BaseTextArea from "@/components/Inputs/BaseTextArea";

  export default {
    name: "SubjectForm",
    components: {BaseTextArea, ListGroupItemComponent},
    data() {
      return {
        labelWidth: 100
      }
    },
    props: {
      subject: {
        type: Object,
        default: () => this.$_.cloneDeep(Subject)
      }
    },
    methods: {
      validate() {
        return new Promise((resolve, reject) => {
          this.$refs.subjectForm.validate()
            .then((success) => {
              if (!success) {
                reject();
              } else {
                resolve(this.subject);
              }
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
    }
  }
</script>

<style scoped>

</style>
