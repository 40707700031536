<template>
  <ul class="list-group list-group-flush">
    <list-group-item-component :label="$t('fields.alias')" :label-width="labelWidth">
      <template slot="value">
        <base-input class="flex-grow-1 mb-0" type="text" v-model="locationLocal.alias"/>
      </template>
    </list-group-item-component>
    <list-group-item-component :label="$t('fields.country')" :label-width="labelWidth">
      <template slot="value">
        <base-input class="flex-grow-1 mb-0">
          <el-select
            class="select-default text-uppercase"
            filterable
            clearable
            :placeholder="$t('fields.type_to_search')"
            v-model="locationLocal.country"
          >
            <el-option
              v-for="(country, index) in countryOptions"
              class="select-default text-uppercase"
              :value="country.value"
              :label="country.label"
              :key="`country-${index}`"
            >
            </el-option>
          </el-select>
        </base-input>
      </template>
    </list-group-item-component>
    <list-group-item-component :label="$t('fields.administrative_area_level_1')" :label-width="labelWidth">
      <template slot="value">
        <base-input class="flex-grow-1 mb-0">
          <el-select
            class="select-default text-uppercase"
            filterable
            clearable
            :placeholder="$t('fields.type_to_search')"
            v-model="locationLocal.region"
          >
            <el-option
              v-for="(region, index) in regionOptions"
              class="select-default text-uppercase"
              :value="region.value"
              :label="region.label"
              :key="`region-${index}`"
            >
            </el-option>
          </el-select>
        </base-input>
      </template>
    </list-group-item-component>
    <list-group-item-component :label="$t('fields.administrative_area_level_2')" :label-width="labelWidth">
      <template slot="value">
        <base-input class="flex-grow-1 mb-0">
          <el-select
            class="select-default text-uppercase"
            filterable
            clearable
            :placeholder="$t('fields.type_to_search')"
            v-model="locationLocal.province"
          >
            <el-option
              v-for="(province, index) in provinceOptions"
              class="select-default text-uppercase"
              :value="province.value"
              :label="province.label"
              :key="`province-${index}`"
            >
            </el-option>
          </el-select>
        </base-input>
      </template>
    </list-group-item-component>
    <list-group-item-component :label="$t('fields.locality')" :label-width="labelWidth">
      <template slot="value">
        <base-input class="flex-grow-1 mb-0" type="text" v-model="locationLocal.locality"/>
      </template>
    </list-group-item-component>
    <list-group-item-component :label="$t('fields.postal_code')" :label-width="labelWidth">
      <template slot="value">
        <base-input class="flex-grow-1 mb-0" type="text" v-model="locationLocal.postal_code"/>
      </template>
    </list-group-item-component>
    <list-group-item-component :label="$t('fields.street_address')" :label-width="labelWidth">
      <template slot="value">
        <base-input class="flex-grow-1 mb-0" type="text" v-model="locationLocal.street_address"/>
      </template>
    </list-group-item-component>
  </ul>
</template>

<script>
import {Select, Option} from 'element-ui';
import {mapGetters} from "vuex";
import Location from "@/models/location";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";

export default {
  name: "LocationForm",
  data() {
    return {
      locationLocal: Location,
      labelWidth: 140
    }
  },
  components: {
    ListGroupItemComponent,
    [Option.name]: Option,
    [Select.name]: Select
  },
  props: {
    location: {
      type: Object,
      default: () => _.cloneDeep(Location)
    }
  },
  mounted() {
    this.locationLocal = this.$_.cloneDeep(this.location);
  },
  methods: {
    validate() {
      return this.locationLocal
    },
  },
  computed: {
    ...mapGetters({
      countries: 'common/countries',
      regions: 'common/regions',
      provinces: 'common/provinces',
    }),

    countryOptions() {
      return this.$_.map(this.countries, country => {
        return {
          label: country.name_it,
          value: country.sigla_alpha_2,
        }
      });
    },
    regionOptions() {
      return this.$_.map(this.regions, region => {
        return {
          label: region.regione,
          value: region.regione,
        }
      });
    },
    provinceOptions() {
      return this.$_.map(this.provinces, province => {
        return {
          label: province.sovracomunale,
          value: province.sigla_automobilistica,
        }
      });
    },
  }
}
</script>

<style scoped>

</style>
