const CourseModule = {
  id: null,
  code: '',
  title: '',
  description: '',
  lesson_number: null,
  subjects: [],
};

export default CourseModule
