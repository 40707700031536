<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="col-auto text-center">
        <div>{{ $t('course.module_wizard.second_step_info') }}</div>
      </div>
    </div>
    <subject-picker ref="subjectPicker"/>
  </div>
</template>

<script>
import SubjectPicker from "@/pages/Didactics/components/SubjectPicker";

export default {
  name: "CourseModuleWizardSecondStep",
  components: {SubjectPicker},
  methods: {
    getSubjects() {
      return this.$refs.subjectPicker.getSubjects();
    },
  }
}
</script>

<style scoped>

</style>
