<template>
  <div class="row">
    <div class="col-12">
      <octo-header-button :title="$t('common.subjects')"/>
    </div>
    <div class="col-12">
      <subject-datatable
        @onOpenSubject="openModal"
        @onAddSubject="openModal"
        :key="`datatable-${subjectDatatableKey}`"
      />
    </div>
    <modal centered :show.sync="showModal">
      <h5 slot="header" class="modal-title text-uppercase">
        {{ $t('course.subject') }}
      </h5>
      <subject-form ref="subjectForm" :subject="currentSubject" :key="`form-${subjectFormKey}`"/>
      <template slot="footer">
        <base-button link @click="saveCurrentSubject">
          {{ $t('common.save') }}
        </base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import SubjectDatatable from "@/pages/Courses/datatables/SubjectDatatable";
import Modal from "@/components/Modal";
import SubjectForm from "@/pages/Courses/components/SubjectForm";
import Subject from "@/models/subject";
import {endpoints} from "@/routes/endpoints";
import {mapActions} from "vuex";

export default {
  name: "DidacticsSubjects",
  components: {SubjectForm, Modal, SubjectDatatable, OctoHeaderButton},
  data() {
    return {
      endpoints: endpoints,
      currentSubject: Subject,
      showModal: false,
      subjectFormKey: 0,
      subjectDatatableKey: 0,
    }
  },
  methods: {
    ...mapActions({
      getSubjects: 'common/getSubjects'
    }),

    openModal(subject) {
      this.currentSubject = this.$_.cloneDeep(this.$_.isEmpty(subject) ? Subject : subject);
      this.showModal = true;
      this.subjectFormKey++;
    },
    saveCurrentSubject: async function () {
      try {
        let subjectData = await this.$refs.subjectForm.validate();

        try {
          this.$fullLoading.show();

          await this.$api({
            method: subjectData.id ? 'put' : 'post',
            url: subjectData.id
              ? endpoints.COURSE_SUBJECT_UPDATE.replace('{id}', subjectData.id)
              : endpoints.COURSE_SUBJECT_STORE,
            data: subjectData
          });

          this.subjectDatatableKey++;
          this.showModal = false;
          this.getSubjects();
          this.$fullLoading.hide();
          this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
        } catch (e) {
          this.$fullLoading.hide();
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        }
      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
      }
    },
  }
}
</script>

<style scoped>

</style>
